var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "match-height"
  }, [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('form-timepicker-basic')], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('form-timepicker-second')], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('form-timepicker-state')], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('form-timepicker-validation')], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('form-timepicker-size')], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('form-timepicker-button-only')], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('form-timepicker-placeholder')], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('form-timepicker-optional')], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('form-timepicker-internationalization')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }