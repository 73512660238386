var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Optional controls"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeOptional) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("Add optional control buttons to the bottom of the calendar popup via the props ")]), _c('code', [_vm._v("now-button")]), _c('span', [_vm._v(" or ")]), _c('code', [_vm._v("reset-button")]), _c('span', [_vm._v(". The default close button can be removed via the ")]), _c('code', [_vm._v("no-close-button")]), _c('span', [_vm._v(" prop.")])]), _c('label', {
    attrs: {
      "for": "timepicker-buttons"
    }
  }, [_vm._v("Time picker with optional footer buttons")]), _c('b-form-timepicker', {
    attrs: {
      "id": "timepicker-buttons",
      "now-button": "",
      "reset-button": "",
      "locale": "en"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }