var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Placeholder"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codePlaceholder) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("Add custom placeholder text to the control, when no date is selected, via the ")]), _c('code', [_vm._v("placeholder")]), _c('span', [_vm._v(" prop. If a placeholder is not provided, the value of the ")]), _c('code', [_vm._v("label-no-time-selected")]), _c('span', [_vm._v(" prop is used.")])]), _c('label', {
    attrs: {
      "for": "timepicker-placeholder"
    }
  }, [_vm._v("Time picker with placeholder")]), _c('b-form-timepicker', {
    attrs: {
      "id": "timepicker-placeholder",
      "placeholder": "Choose a time",
      "local": "en"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }