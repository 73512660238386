var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Validation states"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeValidation) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('code', [_vm._v("<b-form-timepicker>")]), _c('span', [_vm._v(" supports invalid and valid styling via the boolean ")]), _c('code', [_vm._v("state")]), _c('span', [_vm._v(" prop. Setting ")]), _c('code', [_vm._v("state")]), _c('span', [_vm._v(" to boolean ")]), _c('code', [_vm._v("false")]), _c('span', [_vm._v(" will style the input as invalid, while setting it to boolean ")]), _c('code', [_vm._v("true")]), _c('span', [_vm._v(" will style it as valid. Setting state to ")]), _c('code', [_vm._v("null")]), _c('span', [_vm._v(" will not show any validation state styling (the default).")])]), _c('label', {
    attrs: {
      "for": "timepicker-invalid"
    }
  }, [_vm._v("Choose a time (invalid style)")]), _c('b-form-timepicker', {
    staticClass: "mb-2",
    attrs: {
      "id": "datepicker-invalid",
      "state": _vm.selectedTime.length > 0 ? true : false
    },
    model: {
      value: _vm.selectedTime,
      callback: function callback($$v) {
        _vm.selectedTime = $$v;
      },
      expression: "selectedTime"
    }
  }), _c('label', {
    attrs: {
      "for": "timepicker-valid"
    }
  }, [_vm._v("Choose a time (valid style)")]), _c('b-form-timepicker', {
    attrs: {
      "id": "datepicker-valid",
      "state": true
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }