var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Basic Timepicker"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeBasic) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('code', [_vm._v("<b-form-timepicker>")]), _c('span', [_vm._v(" is a BootstrapVue custom time picker input form control, which provides full WAI-ARIA compliance and internationalization support. ")])]), _c('b-form-timepicker', {
    attrs: {
      "locale": "en"
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }), _c('b-card-text', {
    staticClass: "mt-2 mb-0"
  }, [_vm._v(" Value: '" + _vm._s(_vm.value) + "' ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }